import { useState } from 'react';

const ProductImage = ({ src, alt, placeholder }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className="cs_product_thumb">
      <img
        src={imageError || !imageLoaded ? placeholder : src}
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageError}
        style={{ opacity: imageLoaded ? 1 : 0, transition: 'opacity 0.3s ease' }}
      />
      <div className="cs_product_overlay" />
    </div>
  );
};

export default ProductImage;
