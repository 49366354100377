import React, { createContext, useState, useEffect } from 'react';

export const BlogContext = createContext();

const BLOG_STORAGE_KEY = 'blogState';

export const BlogProvider = ({ children }) => {
  const [blog, setBlog] = useState(() => {
    const savedBlog = localStorage.getItem(BLOG_STORAGE_KEY);
    return savedBlog ? JSON.parse(savedBlog) : {
      title: '',
      category: '',
      question: "",
      content: [],
      description: '',
      thumbnailSrc: '',
      subTitle: '',
      paragraph: [],
      paragraph1: [],
      photos: [],
      subTitle1: "",
      author: "",
      list: [],
      blockquote: ''
    };
  });

  useEffect(() => {
    localStorage.setItem(BLOG_STORAGE_KEY, JSON.stringify(blog));
  }, [blog]);

  const updateBlog = (newBlog) => {
    setBlog(newBlog);
  };

  return (
    <BlogContext.Provider value={{ blog, updateBlog }}>
      {children}
    </BlogContext.Provider>
  );
};
