import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
const logo = require("./logo.png");
export default function Layout({ darkMode }) {
  return (
    <div className={`${darkMode ? "cs_dark" : ""}`}>
      <Header
        logoUrl={logo}
        actionBtnText="Getting Started"
        actionBtnUrl="/contact"
      />
      <Outlet />
      <Footer />
    </div>
  );
}
