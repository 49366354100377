import Modal from 'react-bootstrap/Modal';
import SignUpPage from '../Pages/SignUpPage';
import { useTranslation } from 'react-i18next';

function SignUpModal({ show, onHide, title = 'create_account_ontinue' }) {
  const { t } = useTranslation()
  return (
    <Modal show={show} fullscreen={true} onHide={() => onHide(false)}
      data-bs-theme="dark"
      id={"myModal"}
    >
      <Modal.Header className='modal_color' closeButton>
        <Modal.Title>{t(title)}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SignUpPage />
      </Modal.Body>
    </Modal>

  );
}

export default SignUpModal;