import React, { useReducer } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";

import { Button, Grid, TextField, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  handleSocialLogin,
} from "../../firebase/auth";
import { formReducer } from "../../firebase/formReducer";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  errors: {},
};

export default function SignUpPage() {
  pageTitle("Sign Up");

  const onDispatch = useDispatch();
  const navigation = useNavigate();

  const [state, dispatch] = useReducer(formReducer, initialState);
  const { cartItem } = useSelector((state) => state.cartItem);

  const { t } = useTranslation();
  const onSuccess = () => {
    if (cartItem.length === 0) {
      navigation("/shop");
    } else {
      navigation("/shop/cart");
    }

    // if (onHide) {
    //   onHide(false);
    // }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = {};
    if (!state.firstName.trim()) errors.firstName = t("firstNameIsRequired");
    if (!state.lastName.trim()) errors.lastName = t("lastNameIsRequired");
    if (!state.email.trim()) errors.email = t("password_required");
    if (!state.password.trim()) errors.password = t("email_address_required");

    if (Object.keys(errors).length > 0) {
      dispatch({ type: "SET_ERRORS", errors });
      return;
    }

    await createUserWithEmailAndPassword(state, onDispatch, onSuccess);
  };

  const handleInputChange = (event) => {
    dispatch({
      type: "SET_FIELD",
      field: event.target.name,
      value: event.target.value,
    });
  };

  const socialBtnList = [
    {
      icon: "flat-color-icons:google",
      platform: "google",
    },
    {
      icon: "prime:twitter",
      platform: "twitter",
    },
    {
      icon: "mingcute:github-line",
      platform: "github",
    },
  ];

  return (
    <>
      <Spacing lg="30" md="30" />
      <section>
        <div className="container">
          <div className="row justify-content-center align-items-center cs_gap_y_45">
            <div className="col-lg-8">
              <div className="cs_contact_form_wrap_act">
                <div className=" cs_contact_form_bg" />
                {/* <Typography
                  className="modal_color"
                  component="h1"
                  variant="h5"
                  sx={{ textAlign: "center" }}>
                  Sign up
                </Typography> */}
                <Spacing lg="30" md="30" />

                <Grid container justifyContent="center" alignItems="center">
                  <Spacing lg="30" md="30" />
                  <div className="css_social_login">
                    {socialBtnList.map((item, index) => (
                      <Button to={item.href} key={index}>
                        <Icon
                          key={index}
                          icon={item.icon}
                          width="48px"
                          height="48px"
                          onClick={() => handleSocialLogin(item.platform)}
                        />
                      </Button>
                    ))}
                  </div>
                </Grid>
                <Spacing lg="30" md="30" />
                <form className="cs_contact_form">
                  <div className="row g-3">
                    <div className="col-12 col-lg-6">
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        required
                        fullWidth
                        id="firstName"
                        label={t("firstName")}
                        autoFocus
                        value={state.firstName}
                        onChange={handleInputChange}
                        error={!!state.errors.firstName}
                        helperText={state.errors.firstName}
                      />
                    </div>
                    <div className="col-12 col-lg-6 text-lg-end">
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        label={t("lastName")}
                        name="lastName"
                        autoComplete="family-name"
                        value={state.lastName}
                        onChange={handleInputChange}
                        error={!!state.errors.lastName}
                        helperText={state.errors.lastName}
                      />
                    </div>
                  </div>

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("email_address")}
                    name="email"
                    autoComplete="email"
                    value={state.email}
                    onChange={handleInputChange}
                    error={!!state.errors.email}
                    helperText={state.errors.email}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("password")}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={state.password}
                    onChange={handleInputChange}
                    error={!!state.errors.password}
                    helperText={state.errors.password}
                  />
                  <Spacing lg="30" md="30" />
                  <div className="text-center">
                    <button
                      className="cs_btn cs_style_1 w-100 modal_color"
                      onClick={handleSubmit}>
                      {t("send")}
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="row g-3">
                    <div className="col-12 col-lg-6 modal_color">
                      <Link to="/forgot-password" variant="body2">
                        {t("forgot_password")}
                      </Link>
                    </div>
                    <div className="col-12 col-lg-6 text-lg-end modal_color">
                      <Link to="/signin">{t("already_have_an_account")}</Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
