import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import Portfolio from "../Portfolio";
import CtaStyle2 from "../Cta/CtaStyle2";
import { pageTitle } from "../../helpers/PageTitle";
import PortfolioData from "../Portfolio/data";

export default function PortfolioPage() {
  pageTitle("Portfolio");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Resultados Que Hablan: Soluciones Web Personalizadas"
        subTitle="Proyectos Que Definen Nuestro Éxito"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <Portfolio data={PortfolioData} />
        <div className="cs_height_75 cs_height_lg_40" />
      </div>
    </>
  );
}
