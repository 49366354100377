import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { functions } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../../redux/actions";

function Message({ content }) {
  return <p>{content}</p>;
}

const initialOptions = {
  "client-id":
    "AWaZup_4O1dq968C3EfskbUIMeprk9y5nfdm60hhoRojWq1e_DyZEBFPS3yG4aq8CaH3TFG-PPZqeS5D",
  "enable-funding": "paylater,venmo",
  "data-sdk-integration-source": "integrationbuilder_sc",
};

function Payment({ cartItem, totalPrice }) {
  const [message, setMessage] = useState("");
  const { user } = useSelector((state) => state.auth);
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const createOrder = async () => {
    try {
      const createOrderCallable = functions().httpsCallable(
        "payment-createOrderCallable"
      );
      const result = await createOrderCallable({
        total: totalPrice,
      });
      console.log("Order created:", result.data);
      return result.data.id; // Return the PayPal order ID
    } catch (error) {
      console.error("Error creating order:", error);
      setMessage("Failed to create order. Please try again.");
      throw error; // Rethrow to propagate the error to PayPal Buttons
    }
  };

  // Captures the order once it has been approved by the user.
  const captureOrder = async (data) => {
    try {
      const captureOrderCallable = functions().httpsCallable(
        "payment-captureOrderCallable"
      );
      const result = await captureOrderCallable({
        orderID: data.orderID,
        products: cartItem,
        userId: user.id,
      });

      if (result.data) {
        navigation("/order");
        dispatch(clearCart());
      }
      console.log("Order captured:", result.data);
      setMessage("Order completed successfully!");
    } catch (error) {
      console.error("Error capturing order:", error);
      setMessage("Failed to capture order. Please try again.");
    }
  };

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          style={{
            shape: "rect",
            layout: "vertical",
          }}
          createOrder={createOrder} // Properly bind createOrder function
          onApprove={(data, actions) => captureOrder(data)} // Properly bind captureOrder function
          onError={(err) => {
            console.error("Error during PayPal transaction:", err);
            setMessage("An error occurred during the transaction.");
          }}
        />
      </PayPalScriptProvider>
      <Message content={message} />
    </div>
  );
}

export default Payment;
