import React, { useState, useRef } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { Icon } from "@iconify/react";
import { TextField, CircularProgress, Snackbar, Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

export default function ContactPage() {
  pageTitle("Contáctenos");
  const { t } = useTranslation();

  // Estados del formulario
  const [formData, setFormData] = useState({
    email: "",
    phone: "",
    message: "",
    name: "",
  });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const formRef = useRef();

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Manejar envío del formulario con EmailJS
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .sendForm(
        "service_cxc1dmh", // Reemplaza con tu Service ID
        "template_v6svrgu", // Reemplaza con tu Template ID
        formRef.current,
        "QlkAv4-hrP9VfYx3r" // Reemplaza con tu clave pública (User ID)
      )
      .then(
        (result) => {
          console.log("Correo enviado:", result.text);
          setSuccess(true);
          setFormData({ email: "", phone: "", message: "" }); // Reinicia el formulario
        },
        (error) => {
          console.error("Error al enviar el correo:", error);
          setError("Error al enviar el formulario. Intenta de nuevo.");
        }
      )
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="contact_us_for_more_details_and_assistance"
        subTitle="contact"
        shape="shape_6"
      />

      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="cs_gray_bg_3 col-lg-12">
              <div className="cs_contact_form_wrap_act">
                <div className="cs_contact_form_bg" />
                <form
                  className="cs_contact_form"
                  onSubmit={handleSubmit}
                  ref={formRef}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label={"Nombre"}
                    name="name"
                    autoComplete="name"
                    value={formData.name}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("email_address")}
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="phone"
                    label={t("phoneNumber")}
                    name="phone"
                    autoComplete="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  />
                  <div className="cs_height_38 cs_height_lg_25" />

                  <TextField
                    fullWidth
                    label={t("message")}
                    multiline
                    rows={4}
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />

                  <div className="cs_height_38 cs_height_lg_25" />

                  <button
                    className="cs_btn cs_style_1"
                    type="submit"
                    disabled={loading}>
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <>
                        {t("send")}
                        <span>
                          <i>
                            <Icon icon="fa6-solid:arrow-right" />
                          </i>
                          <i>
                            <Icon icon="fa6-solid:arrow-right" />
                          </i>
                        </span>
                      </>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Snackbar para mensajes de éxito y error */}
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}>
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}>
          {t("message_sent_successfully")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError("")}>
        <Alert
          onClose={() => setError("")}
          severity="error"
          sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}
