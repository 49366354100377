const PortfolioData = [
  {
    href: "/portfolio/super-mercado",
    imgUrl: "/images/porfolio/supermarket/act-supermarket.png",
    title: "Supermercado o E-commerce",
    btnText: "Ver Más",
    description: [
      "Este proyecto está diseñado para mejorar la experiencia de compra en línea de un supermercado, facilitando que los clientes encuentren lo que necesitan de manera rápida y eficiente.",
      "Filtros avanzados: Los clientes pueden buscar productos fácilmente por categoría, precio o atributos.",
      "Búsqueda rápida: Proporciona resultados instantáneos mientras los usuarios escriben, mejorando la navegación.",
      "Carrito ágil: Permite a los clientes añadir productos sin interrumpir su experiencia de compra.",
      "Vista rápida de productos: Los clientes pueden ver detalles importantes sin salir de la página del producto.",
      "Sugerencias de productos complementarios: Aumenta las ventas mostrando artículos relacionados o populares.",
      "Comparación y lista de deseos: Facilita la elección de productos y la planificación para futuras compras.",
      "Carrito interactivo: Los clientes pueden ver y editar los productos en su carrito en tiempo real.",
      "Este enfoque está pensado para supermercados y tiendas de alimentos, permitiendo la venta de productos como: ",
      "Frutas y vegetales frescos",
      "Carnes, embutidos y mariscos",
      "Bebidas, jugos, refrescos y cócteles",
      "Snacks, galletas y productos horneados",
      "Artículos de cuidado personal y del hogar",
      "Productos orgánicos y especializados",
      "El objetivo principal es ofrecer una experiencia de compra sencilla y agradable que fomente la lealtad del cliente, aumente las ventas y facilite pagos de manera segura y conveniente."

    ],
    cliente: 'Andrew romania',
    ubicacion: "Santiago",
    proyectoCompletado: "11 Agosto,2022",
    url: "https://supermercado.actdevsystem.com/",
    photos: [
      {
        alt: "Image1's alt text",
        caption: "Rent Car",
        src: "/images/porfolio/supermarket/productos.png",
        caption: "Productos",
      },
      {
        alt: "Image2's alt text",
        caption: "Image2's description",
        src: "/images/porfolio/supermarket/act-supermarket-mobile.png",
        caption: "Supermercado o e-commerce",
      },
      {
        alt: "Image3's alt text",
        caption: "Image3's description",
        src: "/images/porfolio/supermarket/act-cart.png",
        caption: "Supermercado o e-commerce",
      },
      {
        alt: "Image3's alt text",
        caption: "Image3's description",
        src: "/images/porfolio/supermarket/supermaket.png",
        caption: "Supermercado o e-commerce",
      }
    ]

  },
  {
    href: "/portfolio/rent-car",
    imgUrl: "/images/porfolio/rentcar/rentcar.png",
    title: "Rent Car",
    btnText: "Ver Más",
    description: [
      "Act Rent Car es una solución digital diseñada para ofrecer una experiencia única y eficiente en el alquiler de vehículos. Con una plataforma moderna y funcional, conecta a los clientes con la flota de vehículos más adecuada para sus necesidades, garantizando comodidad, rapidez y seguridad en cada reserva.",
      "Reservas rápidas y sencillas: Los usuarios pueden explorar una amplia selección de vehículos, filtrar según sus preferencias y realizar reservas en tiempo real desde cualquier dispositivo.",
      "Gestión avanzada de vehículos: Optimiza la administración de la flota, manteniendo detalles como disponibilidad, precios, ubicación y estado de cada coche en un sistema centralizado.",
      "Pagos seguros y flexibles: Ofrece múltiples métodos de pago con encriptación avanzada, garantizando transacciones confiables y sin complicaciones.",
      "Interfaz intuitiva y responsiva: Un diseño optimizado para dispositivos móviles y computadoras, garantizando una experiencia fluida y agradable en todo momento."
    ],
    cliente: 'Andrew romania',
    ubicacion: "Santiago",
    proyectoCompletado: "11 Agosto,2022",
    url: "https://rentcar.actdevsystem.com/",
    photos: [
      {
        alt: "Image1's alt text",
        caption: "Rent Car",
        src: "/images/porfolio/rentcar/categorias.png",
        caption: "Rent Car",
      },
      {
        alt: "Image2's alt text",
        caption: "Image2's description",
        src: "/images/porfolio/rentcar/mobile-rent-car.png",
        caption: "Rent Car",
      },
      {
        alt: "Image3's alt text",
        caption: "Image3's description",
        src: "/images/porfolio/rentcar/rent-phone.png",
        caption: "Rent Car",
      },
      {
        alt: "Image3's alt text",
        caption: "Image3's description",
        src: "/images/porfolio/rentcar/blog.png",
        caption: "Rent Car",
      }
    ]
  },
  // {
  //   href: "/portfolio/periodico",
  //   imgUrl: "/images/creative-agency/portfolio_3.jpeg",
  //   title: "Periódico",
  //   btnText: "Ver Más",
  //   description: [
  //     "Our t-shirt features a classic design with a crew neckline and short sleeves, making it a versatile piece that can be dressed up or down. The relaxed fit is perfect for all body types, providing ample room for movement and ensuring a flattering silhouette.",
  //     "Available in a range of colors, our men's t-shirt is the perfect choice for any occasion. Wear it with jeans and sneakers for a casual look or dress it up with slacks and a blazer for a more formal event. No matter how you style it, you're sure to look and feel your best. So why wait? Add our men's t-shirt to your wardrobe today and experience the ultimate combination of comfort and style"
  //   ],
  //   cliente: 'Andrew romania',
  //   ubicacion: "Santiago",
  //   proyectoCompletado: "11 Agosto,2022",
  //   url: "https://supermercado.actdevsystem.com/",
  //   photos: [
  //     {
  //       alt: "Image1's alt text",
  //       caption: "Rent Car",
  //       src: "/images/porfolio/t9.00.50 PM.png",
  //       caption: "Rent Car",
  //     },
  //     {
  //       alt: "Image2's alt text",
  //       caption: "Image2's description",
  //       src: "/images/porfolio/t8.58.47 PM.png",
  //       caption: "Rent Car",
  //     },
  //     {
  //       alt: "Image3's alt text",
  //       caption: "Image3's description",
  //       src: "https://actdevsystem.com/images/creative-agency/portfolio_1.jpeg",
  //       caption: "Rent Car",
  //     },
  //   ]
  // },
  // {
  //   href: "/portfolio/e-commerce",
  //   imgUrl: "/images/creative-agency/portfolio_4.jpeg",
  //   title: "E-commerce o Comercio Electrónico",
  //   btnText: "Ver Más",
  //   description: [
  //     "Our t-shirt features a classic design with a crew neckline and short sleeves, making it a versatile piece that can be dressed up or down. The relaxed fit is perfect for all body types, providing ample room for movement and ensuring a flattering silhouette.",
  //     "Available in a range of colors, our men's t-shirt is the perfect choice for any occasion. Wear it with jeans and sneakers for a casual look or dress it up with slacks and a blazer for a more formal event. No matter how you style it, you're sure to look and feel your best. So why wait? Add our men's t-shirt to your wardrobe today and experience the ultimate combination of comfort and style"
  //   ],
  //   cliente: 'Andrew romania',
  //   ubicacion: "Santiago",
  //   proyectoCompletado: "11 Agosto,2022",
  //   url: "https://supermercado.actdevsystem.com/",
  //   photos: [
  //     {
  //       alt: "Image1's alt text",
  //       caption: "Rent Car",
  //       src: "/images/porfolio/t9.00.50 PM.png",
  //       caption: "Rent Car",
  //     },
  //     {
  //       alt: "Image2's alt text",
  //       caption: "Image2's description",
  //       src: "/images/porfolio/t8.58.47 PM.png",
  //       caption: "Rent Car",
  //     },
  //     {
  //       alt: "Image3's alt text",
  //       caption: "Image3's description",
  //       src: "https://actdevsystem.com/images/creative-agency/portfolio_1.jpeg",
  //       caption: "Rent Car",
  //     },
  //   ]
  // },
];


export default PortfolioData