import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function SimpleBackdrop({ isLoading = false }) {
  return (
    <div>
      <Backdrop
        open={isLoading}
        sx={{
          backgroundColor: "#171717",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
