import Swal from "sweetalert2";
import { auth, firestore } from "../firebase";
import { ErrorCode } from "./ErrorCode";
import { setUserData } from "../redux/actions";
import i18n from "../components/Localization/i18n";


const usersRef = firestore().collection("users");

export const handleSocialLogin = (platform) => {
  switch (platform) {
    case "google":
      const googleProvider = new auth.GoogleAuthProvider();
      auth()
        .signInWithPopup(googleProvider)
        .then((result) => {
          // This gives you a Google Access Token. You can use it to access the Google API.
          const token = result.credential.accessToken;
          // The signed-in user info.
          const user = result.user;
          console.log("User info: ", user);
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // The email of the user's account used.
          const email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          const credential = error.credential;
          console.error("Error during sign in: ", error);
        });

      break;
    case "twitter":
      const twitterProvider = new auth.TwitterAuthProvider();
      auth()
        .signInWithPopup(twitterProvider)
        .then(function (result) {
          // For accessing the Twitter API.
          // var token = result.credential.accessToken;
          // var secret = result.credential.secret;
          // // The signed-in user info.
          // var user = result.user;
          console.log(result);
        });
      break;
    case "github":
      let provider = new auth.GithubAuthProvider();
      provider.addScope("repo");
      auth()
        .signInWithPopup(provider)
        .then(function (result) {
          // This gives you a GitHub Access Token.
          var token = result.credential.accessToken;
          // The signed-in user info.
          var user = result.user;
        })
        .catch(function (error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          if (errorCode === "auth/account-exists-with-different-credential") {
            alert(
              "You have signed up with a different provider for that email."
            );
            // Handle linking here if your app allows it.
          } else {
            console.error(error);
          }
        });

      break;
    default:
      console.log("Unknown platform");
  }
};


export const createUserWithEmailAndPassword = async (state, callback, onSuccess) => {

  await auth()
    .createUserWithEmailAndPassword(state.email, state.password)
    .then((response) => {
      const timestamp = firestore.FieldValue.serverTimestamp();
      const uid = response.user.uid;

      const data = {
        id: uid,
        userID: uid,
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
        createdAt: timestamp,
      };

      callback(setUserData({
        id: uid,
        userID: uid,
        email: state.email,
        firstName: state.firstName,
        lastName: state.lastName,
      }))
      onSuccess()
      usersRef
        .doc(uid)
        .set(data)
        .catch((error) => {
          console.log(error);
        });
    })
    .catch((error) => Swal.fire(i18n.t(error.code), "", "error"))
}





export const loginWithEmailAndPassword = async ({ email, password }, callback, onSucess) => {

  auth()
    .signInWithEmailAndPassword(email, password)
    .then((response) => {
      const uid = response.user.uid;
      usersRef
        .doc(uid)
        .get()
        .then(function (firestoreDocument) {
          if (!firestoreDocument.exists) {
            Swal.fire(ErrorCode.noUser, '', "error");
            return;
          }
          const user = firestoreDocument.data();
          callback(setUserData({
            email,
            password,
            id: uid, ...user
          }))
          onSucess()
        })
        .catch(function (_error) {
          Swal.fire(ErrorCode.serverError, '', "error");
        });
    })
    .catch((error) => Swal.fire(i18n.t(error.code), "", "error"))


  //   switch (error.code) {
  //     case 'auth/wrong-password':
  //       Swal.fire(ErrorCode.invalidPassword, '', "error");
  //       break;
  //     case 'auth/network-request-failed':
  //       Swal.fire(ErrorCode.serverError, '', "error");
  //       break;
  //     case 'auth/user-not-found':
  //       Swal.fire(ErrorCode.noUser, '', "error");
  //       break;
  //     default:
  //       Swal.fire(ErrorCode.serverError, '', "error");
  //   }
  //   Swal.fire(ErrorCode.serverError, '', "error")
  // });
}
