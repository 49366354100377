import { LOG_OUT, UPDATE_USER } from './types';

const initialState = {
  user: null,
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      if (!action.data) {
        return state;
      }

      return {
        user: action.data
      };

    case LOG_OUT: {
      return initialState;
    }

    default:
      return state;
  }
};
