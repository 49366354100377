import React from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../helpers/PageTitle";
import { useSelector } from "react-redux";

import { ImageGallery } from "react-image-grid-gallery";

export default function PortfolioDetailsPage() {
  const { portfolio } = useSelector((state) => state.portfolio);
  pageTitle("Portfolio Details");
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title={portfolio.title}
        subTitle="Detalles del proyecto"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="cs_portfolio_details">
          <img src={portfolio.imgUrl} alt="Thumb" />
          <Spacing lg="100" md="40" />
          <div className="cs_portfolio_details_in">
            <ul className="cs_portfolio_details_info cs_mp0">
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Cliente:</h3>
                <p className="mb-0">{portfolio.cliente}</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">Ubicación:</h3>
                <p className="mb-0">{portfolio.ubicacion}</p>
              </li>

              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  Proyecto completado:
                </h3>
                <p className="mb-0">{portfolio.proyectoCompletado}</p>
              </li>
              <li>
                <h3 className="cs_fs_21 mb-0 cs_semibold">
                  <a href={portfolio.url} className="cs_text_btn cs_type1">
                    Visitar Sitio
                  </a>
                </h3>
              </li>
            </ul>
            <div className="cs_portfolio_details_right">
              <h2>Resumen del proyecto</h2>
              {portfolio.description.map((paragra) => (
                <p>{paragra} </p>
              ))}
            </div>
          </div>
          <Spacing lg="115" md="60" />

          <div className="cs_portfolio_details_gallery">
            <ImageGallery
              imagesInfoArray={portfolio.photos}
              columnCount={"auto"}
              columnWidth={330}
              gapSize={24}
            />
          </div>
        </div>
        <Spacing lg="90" md="60" />
      </div>
      <Spacing lg="150" md="80" />
    </>
  );
}
