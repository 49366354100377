import React from "react";
import Spacing from "../Spacing";

import { pageTitle } from "../../helpers/PageTitle";

import { Button, Grid, TextField, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  pageTitle("Forgot Password");

  const { t } = useTranslation();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  const handleSocialLogin = (platform) => {
    switch (platform) {
      case "google":
        // Llama a la función específica para Google
        console.log("Login with Google");
        break;
      case "twitter":
        // Llama a la función específica para Twitter
        console.log("Login with Twitter");
        break;
      case "github":
        // Llama a la función específica para GitHub
        console.log("Login with GitHub");
        break;
      default:
        console.log("Unknown platform");
    }
  };

  const socialBtnList = [
    {
      icon: "flat-color-icons:google",
      platform: "google",
    },
    {
      icon: "prime:twitter",
      platform: "twitter",
    },
    {
      icon: "mingcute:github-line",
      platform: "github",
    },
  ];
  return (
    <>
      {/* <Spacing lg="140" md="80" /> */}
      <Spacing lg="30" md="30" />
      <section>
        <div className="container">
          <div className="row justify-content-center align-items-center cs_gap_y_45">
            <div className="col-lg-8">
              <div className="cs_contact_form_wrap_act">
                <div className=" cs_contact_form_bg" />
                {/* <Typography
                  component="h1"
                  variant="h5"
                  sx={{ textAlign: "center" }}>
                  {t("sign_your_account")}
                </Typography> */}
                <Spacing lg="30" md="30" />

                <Grid container justifyContent="center" alignItems="center">
                  <Spacing lg="30" md="30" />
                  <div className="css_social_login">
                    {socialBtnList.map((item, index) => (
                      <Button to={item.href} key={index}>
                        <Icon
                          key={index}
                          icon={item.icon}
                          width="48px"
                          height="48px"
                          onClick={() => handleSocialLogin(item.platform)}
                        />
                      </Button>
                    ))}
                  </div>
                </Grid>
                <Spacing lg="30" md="30" />
                <form className="cs_contact_form">
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("email_address")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />

                  <Spacing lg="30" md="30" />
                  {/* <div className="cs_height_38 cs_height_lg_25" /> */}
                  <div className="text-center">
                    <button className="cs_btn cs_style_1 w-100">
                      {t("send")}
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="row g-3">
                    <div className=" col-12 col-lg-6">
                      <Link to="/signin" variant="body2">
                        {t("already_have_an_account")}
                      </Link>
                    </div>
                    <div className=" col-12 col-lg-6  text-lg-end ">
                      <Link to="/signup" variant="body2">
                        {t("don't_have_account_Sign_Up")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
