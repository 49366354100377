import React, { useState } from "react";
import Spacing from "../Spacing";

import { pageTitle } from "../../helpers/PageTitle";

import { Button, Grid, TextField } from "@mui/material";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { loginWithEmailAndPassword } from "../../firebase/auth";
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SignInPage({ onHide }) {
  pageTitle("Sign In");

  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { t } = useTranslation();

  const { cartItem } = useSelector((state) => state.cartItem);

  const [data, setData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const onSucess = () => {
    if (cartItem.length === 0) {
      navigation("/shop");
    } else {
      navigation("/shop/cart");
    }

    if (onHide) {
      onHide(false);
    }
  };

  const onHidePres = () => {
    if (onHide) {
      onHide(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await loginWithEmailAndPassword(data, dispatch, onSucess);
    } catch (error) {}
  };

  const handleSocialLogin = (platform) => {
    switch (platform) {
      case "google":
        console.log("Login with Google");
        break;
      case "twitter":
        console.log("Login with Twitter");
        break;
      case "github":
        console.log("Login with GitHub");
        break;
      default:
        console.log("Unknown platform");
    }
  };

  const socialBtnList = [
    {
      icon: "flat-color-icons:google",
      platform: "google",
    },
    {
      icon: "prime:twitter",
      platform: "twitter",
    },
    {
      icon: "mingcute:github-line",
      platform: "github",
    },
  ];

  return (
    <>
      <Spacing lg="30" md="30" />
      <section>
        <div className="container">
          <div className="row justify-content-center align-items-center cs_gap_y_45">
            <div className="col-lg-8">
              <div className="cs_contact_form_wrap_act">
                <div className=" cs_contact_form_bg" />
                {/* <Typography
                  component="h1"
                  variant="h5"
                  sx={{ textAlign: "center" }}>
                  Sign in to your account
                </Typography> */}
                <Spacing lg="30" md="30" />

                <Grid container justifyContent="center" alignItems="center">
                  <Spacing lg="30" md="30" />
                  <div className="css_social_login">
                    {socialBtnList.map((item, index) => (
                      <Button
                        key={index}
                        onClick={() => handleSocialLogin(item.platform)}>
                        <Icon icon={item.icon} width="48px" height="48px" />
                      </Button>
                    ))}
                  </div>
                </Grid>
                <Spacing lg="30" md="30" />
                <form className="cs_contact_form" onSubmit={handleSubmit}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={t("email_address")}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={data.email}
                    onChange={handleChange}
                  />

                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("password")}
                    type="password"
                    id="password"
                    value={data.password}
                    onChange={handleChange}
                  />
                  <Spacing lg="30" md="30" />
                  <div className="text-center">
                    <button
                      className="cs_btn cs_style_1 w-100 modal_btn modal_color"
                      type="submit">
                      {t("send")}
                      <span>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                        <i>
                          <Icon icon="fa6-solid:arrow-right" />
                        </i>
                      </span>
                    </button>
                  </div>
                  <Spacing lg="30" md="30" />
                  <div className="row g-3">
                    <div className=" col-12 col-lg-6 modal_color">
                      <Link
                        to="/forgot-password"
                        variant="body2"
                        onClick={onHidePres}>
                        {t("forgot_password")}
                      </Link>
                    </div>
                    <div className=" col-12 col-lg-6  text-lg-end modal_color">
                      <Link to="/signup" onClick={onHidePres} variant="body2">
                        {t("don't_have_account_Sign_Up")}
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
