import React, { useState } from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import Ratings from "../../Ratings";
import FormateNumber from "../../../helpers/FormateNumber";
import ImageView from "../../ShopComponents/ImageView";
import CartAmountToggle from "../../ShopComponents/CartAmountToggle";
import { NavLink } from "react-router-dom";
import { pageTitle } from "../../../helpers/PageTitle";
import TeamMember from "../../TeamMember";
import SectionHeading from "../../SectionHeading";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/actions";

export default function ProductDetails() {
  pageTitle("Product Details");

  const { products } = useSelector((state) => state.product);

  const dispatch = useDispatch();

  const handleAddToCart = () => {
    dispatch(addToCart(products));
  };

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title={products.title}
        subTitle="Shop"
        variant="text-center"
        href="/shop"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <ImageView photo={products.productThumb} />
          </div>
          <div className="col-lg-6">
            <div className="cs_single-product-details">
              <h2>Awesome men t-shirt</h2>
              <div className="cs_single_product-price_review">
                <div className="cs_single_product_price cs_accent_color cs_primary_font cs_semi_bold">
                  <FormateNumber number={products.price} />
                </div>
              </div>
              <div className="cs_height_25 cs_height_lg_25" />
              <div className="cs_single-product-details-text">
                {products.description?.map((description, key) => (
                  <p key={key}>{description}</p>
                ))}
              </div>
              <div className="cs_height_35 cs_height_lg_35" />
              <div className="cs_quantity_and_btn">
                <NavLink
                  to="/shop/cart"
                  className="cs_product_btn cs_semi_bold"
                  onClick={handleAddToCart}>
                  Add to cart
                </NavLink>
              </div>
              <div className="cs_height_40 cs_height_lg_30" />
            </div>
          </div>
        </div>
        <Spacing lg="100" md="60" />
      </div>

      <SectionHeading
        title="Meet our experts team behind <br />the zivan agency"
        subTitle="Our Team"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row cs_gap_y_35">
            {products.photos?.map(({ url, title }) => (
              <div className="col-lg-4 col-sm-6">
                <TeamMember
                  memberImg={url}
                  memberName={title}
                  memberDesignation="React Developer"
                  href="/team-details"
                />
              </div>
            ))}
          </div>
        </div>
        <Spacing lg="75" md="60" />
      </section>
    </>
  );
}
