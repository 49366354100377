import React, { useContext } from "react";
import Spacing from "../Spacing";
import SectionHeadingStyle3 from "../SectionHeading/SectionHeadingStyle3";
import { Icon } from "@iconify/react";
import AuthorWidget from "../Widget/AuthorWidget";
import { pageTitle } from "../../helpers/PageTitle";
import { BlogContext } from "../Context/BlogProvider";
import { useTranslation } from "react-i18next";

export default function BlogDetailsPage() {
  pageTitle("Blog Details");
  const { blog } = useContext(BlogContext);
  const {
    title,
    thumbnailSrc,
    subTitle,
    paragraph,
    photos,
    subTitle1,
    paragraph1,
    blockquote,
    author,
    list,
    question,
    content,
    category,
    description,
  } = blog;

  const { t } = useTranslation();
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      {title && (
        <SectionHeadingStyle3
          title={t(title)}
          variant="text-center"
          shape="shape_5"
          category={category}
          date="05 Sept 2024"
          avatar="Tech"
          avatarLink="/"
        />
      )}
      <Spacing lg="75" md="60" />
      <div className="container">
        {thumbnailSrc && (
          <div className="col-md-12">
            <img src={thumbnailSrc} alt="Blog" className="cs_radius_15 w-100" />
          </div>
        )}
        <div className="cs_height_60 cs_height_lg_40" />
        <div className="cs_post_details act">
          {description && <p>{t(description)}</p>}
          {question && <h2>{t(question)}</h2>}
          {paragraph?.map((p, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: t(p) }} />
          ))}

          <div className="cs_height_10 cs_height_lg_5" />
          <div className="row">
            {photos?.map((photos) => (
              <div className="col-md-4">
                <img src={photos} alt="Thumb" className="w-100" />
              </div>
            ))}
          </div>
          <div className="cs_height_10 cs_height_lg_5" />
          {content &&
            content.map((item, index) => {
              return (
                <>
                  <h2>{t(item.title)}</h2>
                  <div className="col-md-12">
                    {item.photo && (
                      <img src={item.photo} alt="Thumb" className="w-100" />
                    )}
                  </div>
                  {item.paragraph?.map((p, index) => (
                    <p key={index} dangerouslySetInnerHTML={{ __html: t(p) }} />
                  ))}
                  {item.list?.map((list) => (
                    <div>
                      <h5>{t(list.title)}</h5>
                      {list.data.map((item) => (
                        <ul>
                          <li>{t(item)}</li>
                        </ul>
                      ))}
                    </div>
                  ))}
                </>
              );
            })}
          {/* {subTitle1 && <h3>{subTitle1}</h3>}
          {paragraph1?.map((p, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: p }} />
          ))} */}

          {blockquote && (
            <blockquote>
              {blockquote}
              <small>{author}</small>
            </blockquote>
          )}

          {list?.map((list) => (
            <ul>
              <li>{t(list)}</li>
            </ul>
          ))}
        </div>
        <div className="cs_height_30 cs_height_lg_10" />
        <div className="cs_post_share">
          <div className="cs_categories">
            {/* <a href="/" className="cs_category">
              Marketing
            </a>
            <a href="/" className="cs_category">
              Campaign
            </a> */}
          </div>
          <div className="text-center">
            <div className="cs_post_share_btns">
              <a href="/">
                <Icon icon="fa6-brands:twitter" />
              </a>
              <a href="/">
                <Icon icon="fa6-brands:facebook-f" />
              </a>
              <a href="/">
                <Icon icon="fa6-brands:linkedin-in" />
              </a>
            </div>
            <span className="cs_post_share_title">Share this</span>
          </div>
        </div>
        <div className="cs_height_50 cs_height_lg_40" />
        {/* <AuthorWidget /> */}
      </div>
    </>
  );
}
