
import Modal from 'react-bootstrap/Modal';
import SignInPage from '../Pages/SignInPage';
import { useTranslation } from 'react-i18next';
function SignInModal({ show, onHide, title = 'Sign in & continue' }) {
  const { t } = useTranslation()
  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => onHide(false)}
        data-bs-theme="dark"
        id={"myModal"}
      >
        <Modal.Header className='modal_color' closeButton>
          <Modal.Title>{t(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SignInPage
            onHide={onHide}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SignInModal;