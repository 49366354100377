import React, { useEffect, useState } from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import { pageTitle } from "../../../helpers/PageTitle";
import { Button } from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import FormateNumber from "../../../helpers/FormateNumber";
import { functions } from "../../../firebase";
import SimpleBackdrop from "../../Loader";

export default function Cart() {
  pageTitle("Cart");
  const dispatch = useDispatch();
  const { cartItem, totalPrice } = useSelector((state) => state.cartItem);
  const user = useSelector((state) => state.auth.user);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch({ type: "CALCULATE_TOTAL" });
  }, [totalPrice, cartItem]);

  useEffect(() => {
    if (user?.id) {
      getOrder();
    }
  }, []);

  const getOrder = async () => {
    try {
      const getOrdersByUserId = functions().httpsCallable(
        "payment-getOrdersByUserId"
      );
      const result = await getOrdersByUserId({
        userId: user.id,
      });

      setOrders(result.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error creating order:", error);
      throw error;
    }
  };

  const getDocument = async (sku) => {
    const onDocument = functions().httpsCallable("payment-getDocument");
    const result = await onDocument({ sku });
    if (result.data) {
      const url = result.data;
      const link = document.createElement("a");
      link.href = url;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("No se recibió una URL para descargar el archivo.");
    }
  };

  return (
    <>
      <SimpleBackdrop isLoading={isLoading} />
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      {orders.length >= 1 ? (
        <SectionHeadingStyle3
          title="Descarga tu producto"
          subTitle="Shop"
          variant="text-center"
          href="/shop"
        />
      ) : (
        <SectionHeadingStyle3
          title="You haven't bought anything yet."
          subTitle="Shop"
          description="Discover the most popular items available or browse our hottest new items."
          variant="text-center"
          href="/shop"
        />
      )}
      <Spacing lg="75" md="60" />

      {orders.length >= 1 ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10 ">
              <div className="table-responsive">
                <table className="cs_cart_table">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Price</th>
                      <th>Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order) => {
                      return order.products.map((item) => {
                        return (
                          <tr>
                            <td>
                              <div className="cs_cart_table_media">
                                <img src={item.productThumb} alt="Thumb" />
                                <h3>{item.title}</h3>
                              </div>
                            </td>
                            <td>
                              <FormateNumber number={item.price} />
                            </td>
                            <td>
                              <div className="cs_cart_table_actions">
                                <Button>
                                  <Icon
                                    icon="mdi:download"
                                    width="28px"
                                    height="28px"
                                    onClick={() => getDocument(item.sku)}
                                  />
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      });
                    })}
                  </tbody>
                </table>
              </div>
              <div className="cs_height_5 cs_height_lg_5" />
              <div className="cs_height_30 cs_height_lg_30" />
            </div>
          </div>
        </div>
      ) : null}
      <Spacing lg="120" md="50" />
    </>
  );
}
