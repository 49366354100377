import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyCQHkJzWZEMdIYVRtWXBecla_aTe6_zZ44",
  authDomain: "development-87326.firebaseapp.com",
  projectId: "development-87326",
  storageBucket: "development-87326.appspot.com",
  messagingSenderId: "127107964334",
  appId: "1:127107964334:web:699df2df85991b351b7c3f"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const auth = firebase.auth;
const firestore = firebase.firestore;
const functions = firebase.functions;
const storage = firebase.storage

// if (window.location.hostname === "localhost") {
//   const localIp = '192.168.1.24';

//   //   Android Physical Device
//   auth().useEmulator(`http://${localIp}:9005`);
//   firestore().useEmulator(localIp, 8080);
//   functions().useEmulator(localIp, 5001);
//   // const whyDidYouRender = require('@welldone-software/why-did-you-render');
//   // whyDidYouRender(React, {});
// }

export { firebase, auth, firestore, functions, storage };
