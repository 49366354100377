import { ADD_TO_CART, CALCULATE_TOTAL, CLEAR_CART, REMOVE_FROM_CART, UPDATE_QUANTITY } from './types';

const initialState = {
  cartItem: [],
};

export const cartReducer = (state = initialState, action) => {

  switch (action.type) {
    case ADD_TO_CART:
      const itemExists = state.cartItem.some(item => item.id === action.payload.id);
      if (itemExists) {
        return state
      }
      return {
        ...state,
        cartItem: [...state.cartItem, action.payload],
      };

    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItem: state.cartItem.filter(item => item.id !== action.payload),
      };

    case CLEAR_CART:
      return {
        ...state,
        cartItem: [],
      };

    case UPDATE_QUANTITY:
      return {
        ...state,
        cartItem: state.cartItem.map(item =>
          item.id === action.payload.productId
            ? { ...item, quantity: action.payload.quantity }
            : item
        ),
      };
    case CALCULATE_TOTAL:
      const { totalPrice, totalItems } = state.cartItem.reduce(
        (totals, item) => {
          totals.totalPrice += item.price;
          totals.totalItems += 1;
          return totals;
        },
        {
          totalPrice: 0,
          totalItems: 0
        }
      );

      return {
        ...state,
        totalPrice: totalPrice,
        totalItems: totalItems
      };
    default:
      return state;
  }
};