import React from "react";
import Masonry from "react-masonry-css";
import { Link, NavLink } from "react-router-dom";
import data from "./data";
import { useDispatch, useSelector } from "react-redux";
import { setPortfolio } from "../../redux/actions";

export default function Portfolio() {
  const breakpoints = {
    default: 2,
    991: 1,
  };

  const { portfolio } = useSelector((state) => state.portfolio);

  console.log(portfolio);

  const dispatch = useDispatch();
  const hadledProduct = (item) => {
    dispatch(setPortfolio({ ...item }));
  };

  return (
    <>
      <Masonry
        breakpointCols={breakpoints}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column">
        {data.map((item, index) => (
          <div key={index} className="masonry-item">
            <div className="cs_portfolio cs_style_1">
              <Link
                onClick={() => hadledProduct(item)}
                to={item.href}
                className="cs_portfolio_thumb cs_radius_15">
                <img src={item.imgUrl} alt="Portfolio" />
              </Link>
              <div className="cs_portfolio_info">
                <h2 className="cs_portfolio_title cs_fs_38">
                  <NavLink onClick={() => hadledProduct(item)} to={item.href}>
                    {item.title}
                  </NavLink>
                </h2>
                <NavLink
                  onClick={() => hadledProduct(item)}
                  to={item.href}
                  className="cs_portfolio_btn">
                  {item.btnText}
                </NavLink>
              </div>
            </div>
          </div>
        ))}
      </Masonry>
    </>
  );
}
