import React, { useEffect, useState } from "react";
import Spacing from "../../Spacing";
import SectionHeadingStyle3 from "../../SectionHeading/SectionHeadingStyle3";
import CartAmountToggle from "../../ShopComponents/CartAmountToggle";
import { Link } from "react-router-dom";
import { pageTitle } from "../../../helpers/PageTitle";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import { useDispatch, useSelector } from "react-redux";
import { removeFromCart } from "../../../redux/actions";
import FormateNumber from "../../../helpers/FormateNumber";
import Modal from "react-bootstrap/Modal";
import CustomModal from "../../Modal/signUp";
import Payment from "../../Payment";
import { useTranslation } from "react-i18next";

export default function Cart() {
  pageTitle("Cart");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { cartItem, totalPrice } = useSelector((state) => state.cartItem);
  const user = useSelector((state) => state.auth.user);

  const { t } = useTranslation();

  useEffect(() => {
    dispatch({ type: "CALCULATE_TOTAL" });
  }, [totalPrice, cartItem]);

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="shopping_cart"
        subTitle="shop"
        variant="text-center"
        href="/shop"
      />
      <Spacing lg="75" md="60" />
      <CustomModal show={show} onHide={setShow} />
      {cartItem.length >= 1 ? (
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="table-responsive">
                <table className="cs_cart_table">
                  <thead>
                    <tr>
                      <th>{t("products")}</th>
                      <th>{t("prices")}</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {cartItem.map((item) => {
                      return (
                        <tr>
                          <td>
                            <div className="cs_cart_table_media">
                              <img src={item.productThumb} alt="Thumb" />
                              <h3>{item.title}</h3>
                            </div>
                          </td>
                          <td>
                            <FormateNumber number={item.price} />
                          </td>
                          <td>
                            <div className="cs_cart_table_actions">
                              <Button>
                                <Icon
                                  icon="mdi:remove"
                                  width="28px"
                                  height="28px"
                                  onClick={() =>
                                    dispatch(removeFromCart(item.id))
                                  }
                                />
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="cs_height_5 cs_height_lg_5" />

              <div className="cs_height_30 cs_height_lg_30" />
            </div>
            <div className="col-xl-4">
              <div className="cs_shop-side-spacing">
                <div className="cs_shop-card">
                  <h2>{t("order_summary")}</h2>
                  <table className="cs_medium">
                    <tbody>
                      <tr>
                        <td className="cs_semi_bold">Subtotal</td>
                        <td className="text-end">
                          <FormateNumber number={totalPrice} />
                        </td>
                      </tr>
                      <tr className="cs_semi_bold">
                        <td>Total</td>
                        <td className="text-end">
                          <FormateNumber number={totalPrice} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="cs_height_30 cs_height_lg_30" />
                  {user?.id ? (
                    <Payment cartItem={cartItem} totalPrice={totalPrice} />
                  ) : (
                    // <Link
                    //   className="cs_product_btn cs_semi_bold w-100"
                    //   // to="/shop/checkout"
                    //   // onClick={() => setShow(true)}
                    // >
                    //   Procced To Checkout
                    // </Link>
                    <Link
                      className="cs_product_btn cs_semi_bold w-100"
                      // to="/signup"
                      onClick={() => setShow(true)}>
                      {t("go_to_Checkout")}
                    </Link>
                  )}
                </div>
                <div className="cs_height_30 cs_height_lg_30" />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      <Spacing lg="120" md="50" />
    </>
  );
}
