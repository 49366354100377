import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  Redirect,
  redirect,
} from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../components/Pages/Home";
import MarketingAgencyPage from "../components/Pages/MarketingAgencyPage";
import StudioAgencyPage from "../components/Pages/StudioAgencyPage";
import DigitalAgencyPage from "../components/Pages/DigitalAgencyPage";
import ServicePage from "../components/Pages/ServicePage";
import AboutPage from "../components/Pages/AboutPage";
import BlogPage from "../components/Pages/BlogPage";
import BlogListPage from "../components/Pages/BlogListPage";
import BlogDetailsPage from "../components/Pages/BlogDetailsPage";
import PortfolioPage from "../components/Pages/PortfolioPage";
import PortfolioDetailsPage from "../components/Pages/PortfolioDetailsPage";
import CaseStudyDetailsPage from "../components/Pages/CaseStudyDetailsPage";
import TeamPage from "../components/Pages/TeamPage";
import TeamDetailsPage from "../components/Pages/TeamDetailsPage";
import ContactPage from "../components/Pages/ContactPage";
import Layout2 from "../components/Layout/Layout2";
import TechStartupPage from "../components/Pages/TechStartupPage";
import ServiceDetailsPage from "../components/Pages/ServiceDetailsPage";
import Shop from "../components/Pages/Shop";
import ProductDetails from "../components/Pages/Shop/ProductDetails";
import Cart from "../components/Pages/Shop/Cart";
import Checkout from "../components/Pages/Shop/Checkout";
import Success from "../components/Pages/Shop/Success";
import Wishlist from "../components/Pages/Shop/Wishlist";
import Layout3 from "../components/Layout/Layout3";
import ErrorPage from "../components/Pages/ErrorPage";
import { BlogProvider } from "../components/Context/BlogProvider";
import SignInPage from "../components/Pages/SignInPage";
import SignUpPage from "../components/Pages/SignUpPage";
import ForgotPassword from "../components/Pages/ForgotPassword";
import Downloads from "../components/Pages/Shop/Order";
import Order from "../components/Pages/Shop/Order";
import { auth } from "../firebase";

import ProtectedRoute from "./ProtectedRoute";

function Navigation() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    auth().onAuthStateChanged(async (user) => {
      if (user?.uid) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });
  }, [setIsLoggedIn]);

  return (
    <BlogProvider>
      <Routes>
        <Route path="/" element={<Layout darkMode />}>
          <Route path="about" element={<AboutPage darkMode />} />
          <Route path="service" element={<ServicePage />} />
          <Route
            path="service/:serviceDetailsId"
            element={<ServiceDetailsPage />}
          />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-list" element={<BlogListPage />} />
          <Route path="blog/:blogDetailsId" element={<BlogDetailsPage />} />
          <Route path="portfolio" element={<PortfolioPage />} />
          <Route
            path="portfolio/:portfolioDetailsId"
            element={<PortfolioDetailsPage />}
          />

          <Route path="contact" element={<ContactPage />} />
          <Route path="signin" element={<SignInPage />} />
          <Route path="signup" element={<SignUpPage />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </Route>
        <Route path="/" element={<Layout2 darkMode />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="/" element={<Layout3 darkMode />}>
          <Route path="shop" element={<Shop />} />
          <Route path="shop/:productId" element={<ProductDetails />} />
          <Route path="shop/cart" element={<Cart />} />
          <Route path="shop/checkout" element={<Checkout />} />
          <Route path="shop/success" element={<Success />} />
          <Route path="shop/wishlist" element={<Wishlist />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />

        <Route path="/" element={<Layout2 darkMode />}>
          <Route
            path="order"
            element={
              <ProtectedRoute isLoggedIn={isLoggedIn}>
                <Order />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </BlogProvider>
  );
}

export default Navigation;
